// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import user from "./user";
import recharges from "./recharge";

const rootReducer = {
  user,
  recharges,
  navbar,
  layout,
};

export default rootReducer;
