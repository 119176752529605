// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

// ** UseJWT import to get config
import useJwt from "../auth/jwt/useJwt";

const config = useJwt.jwtConfig;

const initialUser = () => {
  const item = window.localStorage.getItem("user");
  //** Parse stored json or if none return initialValue
  return item
    ? JSON.parse(item)
    : {
        ability: [],
        accessToken: "",
        active: null,
        full_name: "",
        iduser: null,
        role: null,
        username: null,
      };
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUser(),
  reducers: {
    executeLogin: (state, action) => {
      state = action.payload;
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName];
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName];
      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorage.setItem(
        config.storageTokenKeyName,
        action.payload.accessToken,
      );
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        action.payload.refreshToken,
      );
      return state;
    },
    executeLogout: (state) => {
      state = {};
      state[config.storageTokenKeyName] = null;
      state[config.storageRefreshTokenKeyName] = null;
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.clear();
    },
  },
});

export const { executeLogin, executeLogout } = userSlice.actions;

export default userSlice.reducer;
