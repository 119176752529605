// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

// ** UseJWT import to get config
import useJwt from "../auth/jwt/useJwt";

const config = useJwt.jwtConfig;

export const rechargesSlice = createSlice({
  name: "recharges",
  initialState: {
    count: 0,
    page: 0,
    pages: 0,
    rows: [],
  },
  reducers: {
    fetch: (state, action) => {
      state = {
        ...state,
        ...action.payload,
      };
      localStorage.setItem("recharges", JSON.stringify(action.payload));
      return state;
    },
    addNote: (state, action) => {
      const newRows = [...state.rows];

      const index = newRows.findIndex(
        (r) => action.payload.id_recharge == r.idrecharge,
      );

      newRows[index].recharges_notes.push(action.payload);
      state = {
        ...state,
        rows: newRows,
      };

      localStorage.setItem("recharges", JSON.stringify(newRows));
    },
    flagLocalRecharge: (state, action) => {
      const newRows = [...state.rows];

      const index = newRows.findIndex(
        (r) => action.payload.idrecharge == r.idrecharge,
      );

      if (index != -1) {
        newRows[index].flagged = action.payload.flagged;

        state = {
          ...state,
          rows: newRows,
        };

        localStorage.setItem("recharges", JSON.stringify(newRows));
      }
    },
    updateLocalRecharge: (state, action) => {
      const newRows = [...state.rows];

      const index = newRows.findIndex(
        (r) => action.payload.idrecharge == r.idrecharge,
      );

      if (index != -1) {
        newRows[index] = action.payload;

        state = {
          ...state,
          rows: newRows,
        };

        localStorage.setItem("recharges", JSON.stringify(newRows));

        return state;
      }
    },
    addLocalRecharge: (state, action) => {
      const newRows = [...state.rows];

      newRows.unshift(action.payload.payload);
      newRows.pop();

      state = {
        ...state,
        rows: newRows,
      };

      localStorage.setItem("recharges", JSON.stringify(newRows));
      return state;
    },
  },
});

export const {
  fetch,
  updateLocalRecharge,
  addNote,
  flagLocalRecharge,
  addLocalRecharge,
} = rechargesSlice.actions;

export default rechargesSlice.reducer;
