// ** Auth Endpoints
export default {
  loginEndpoint: `/api/user/login`,
  logoutEndpoint: `/jwt/logout`,
  fetchRechargesEndpoint: `/api/recharges/list`,
  addNoteEndpoint: `/api/recharges/addNote`,
  updateRechargeEndpoint: `/api/recharges/update`,
  statsEndpoint: `/api/recharges/stats`,
  todayStatsEndpoint: `/api/recharges/todayStats`,
  // registerEndpoint: `/jwt/register`,
  // refreshEndpoint: `/jwt/refresh-token`,
  resendEndpoint: `/api/recharges/resend`,
  getChannelsEndpoint: `/api/recharges/channels`,
  updateChannels: `/api/recharges/updateChannels`,
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: `Bearer`,

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: `accessToken`,
  storageRefreshTokenKeyName: `refreshToken`,
};
